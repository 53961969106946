import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import Absa from './components/Absa';
import PaypalCheckout from "./components/paypal/paypal";
import Contributions from "./components/mpesa/contributions";


function App() {

  return (
    <Router>
    <div className="App">
      <header className="App-header">
        <Switch>
          <Route exact path="/signed_data/:uuid">
            <Absa/>
          </Route>
          <Route exact path="/paypal/:uuid">
            <PaypalCheckout/>
          </Route>
          <Route exact path="/mpesa/contributions">
            <Contributions/>
          </Route>
        </Switch>
       
      </header>
    </div>
    </Router>
    
  
  );
}

export default App;
