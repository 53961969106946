import React, { useState, useEffect } from "react";
import axios from "axios";
import image from "./contributions.png";
import "./styles.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Text, TextInput, Button } from "@mantine/core";
import { Notification } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";

let schema = yup.object().shape({
  phone: yup.string().required("Phone Number is required"),
  amount: yup.number().required().positive("Amount cannot be 0").integer(),
});

export default function Contributions() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const url = `${process.env.REACT_APP_BASE_PAYMENT_URL}login/access-token`;

  const submitData = (data) => {
    // add login params
    const params = new URLSearchParams();
    params.append("username", process.env.REACT_APP_PAYMENTS_USER);
    params.append("password", process.env.REACT_APP_PAYMENTS_PASSWRD);

    axios
      .post(url, params)
      .then((response) => {
        const token = response.data.access_token;
        const dataUrl = `${process.env.REACT_APP_PAYMENT_URL}/mpesa_payment`;
        const headers = { Authorization: `Bearer ${token}` };

        axios
          .post(dataUrl, data, { headers })
          .then((res) => {
            setLoading(false);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
            }, 1000 * 4);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 1000 * 4);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="contributions">
      <div className="padded">
        <div className="title-cont">We need your help</div>
        {/* <div className="title-cont">Because we’re not giving up</div> */}
        <p className="descr">
          Ntwali Ayisi was born on the 2nd of July, 2022. He shortly after
          developed breathing challenges for which an echocardiogram test had to
          be conducted. The results showed that he has an{" "}
          <span>Unbalanced Complete AV canal defect</span>, and he has to
          undergo an open heart to save his life. We are kindly appealing for
          your support in raising <span>Kes 2 million</span> for him to undergo
          treatment in India Thank you and be blessed.
        </p>
        <h1>Urgent Medical Appeal</h1>
        <h2>Bill: Ksh 2 Million</h2>
        <div className="phone-form">
          <h4>
            <b>Donate Now</b>
          </h4>
          <Formik
            initialValues={{
              amount: 0,
              phone: "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              setLoading(true);
              let data = {
                user_id: "f135004a-8c26-490e-904c-4ec765fb9210",
                order_uuid: "Contributions",
                amount: Number(values.amount),
                acc_ref: "CONTRIBUTIONS",
                phone_number: parseInt(values.phone),
                business_code: 174379,
                description: "Contributions",
              };
              submitData(data);
            }}
          >
            {({ isSubmitting, handleSubmit, errors, touched, values }) => (
              <Form>
                <div style={{ paddingBottom: 2 }}>
                  <TextInput
                    defaultValue={0}
                    placeholder="Enter an Amount"
                    label="Amount"
                    id="amount"
                    name="amount"
                    onChange={(e) => {
                      values.amount = Number(e.target.value);
                    }}
                    withAsterisk
                  />
                  {errors.amount && touched.amount && (
                    <Text size={"sm"} color="red">
                      {errors.amount}
                    </Text>
                  )}
                </div>
                <div style={{ marginBlock: 12 }}>
                  <Text size="md">Phone Number</Text>
                  <PhoneInput
                    country={"ke"}
                    onlyCountries={["ke"]}
                    value={values.phone}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    onChange={(phone) => {
                      values.phone = phone;
                    }}
                  />
                  {errors.phone && touched.phone && (
                    <Text size={"sm"} color="red">
                      {errors.phone}
                    </Text>
                  )}
                </div>
                <Button
                  id="submit"
                  loading={loading}
                  onClick={handleSubmit}
                  size="md"
                >
                  Donate
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <p>
          <b>Reach us on:</b>{" "}
        </p>
        <p className="contact">
          Nadia- +254 718 103 593, Lawi- +254 713 348 090
        </p>
      </div>
      <div>
        <img className="brand-img" src={image} alt="contributions" />
      </div>
      <div
        style={{
          position: "fixed",
          right: 20,
          bottom: 20,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        {success && (
          <Notification
            icon={<IconCheck size={18} />}
            color="teal"
            title="Success"
          >
            An M-Pesa Push notification has been sent to your device.
          </Notification>
        )}
        {error && (
          <Notification icon={<IconX size={18} />} title="Failed" color="red">
            Failed to trigger transaction. Please try again later
          </Notification>
        )}
      </div>
    </div>
  );
}
